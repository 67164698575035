import React from "react";
import { Grid, Button, Typography } from "@mui/material";

export const GlobalRegisterLink = () => {
  return (
    <div style={{ marginTop: "10px" , paddingBottom: "5vh"}}>
      <Grid
        container
        direction={"row"}
        justifyContent={"center"}
        spacing={4}
      >
        <Grid item>
          <Button
            href="https://dancestudio-pro.com/online/flacosdancefactory"
            target={"_blank"}
            rel={"noreferrer"}
            variant="contained"
            fullWidth
          >
            <Typography variant="body1">Click here to Register</Typography>
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
