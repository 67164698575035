import React, { useState } from "react";
import SchoolImage from "../components/Flaco.jpeg";
import { GlobalCardComponent } from "../global-resources/GlobalCardComponent";

export default function SchoolCard() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  const titleString = `In-School Programming`;
  return (
    <div>
      <GlobalCardComponent
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        title={titleString}
        image={SchoolImage}
        alt={"In-School Programming"}
      >
        We offer dance programs for school districts and children of almost all
        ages through our affiliations with such first rate arts education
        agencies as Young Audiences of NJ & Eastern PA, Perkins Center for the
        Arts, to name a few. If you are interested in bringing an arts
        experience into your school conducted by a professional teaching artist,
        contact us for information.
        <br />
        <iframe
          width="100%"
          height="451"
          src="https://www.youtube.com/embed/FHJb1M-8j4o"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </GlobalCardComponent>
    </div>
  );
}
