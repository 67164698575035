import React, { useState } from "react";
import { GlobalCardComponent } from "../global-resources/GlobalCardComponent";

export default function TeamBuilding() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setOpen((prev) => !prev);
  };
  const titleString = `Awesome Team Building`;
  return (
    <div>
      <GlobalCardComponent
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        title={titleString}
        image="https://images.unsplash.com/photo-1502444330042-d1a1ddf9bb5b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1173&q=80"
        alt={"Team Building Services"}
      >
        <strong>Question:</strong>
        <br />
        When is a Professional Development NOT a Professional Development??
        <br />
        <br />
        <strong>Answer:</strong>
        <br />
        when it's disguised as an office party!!
        <br />
        <br />
        Invite your employees to a "thank you" party...one that will change your
        office overnight. Trust us, our exit survey will confirm just that Our
        dance-based team building experience is especially designed to get your
        people to unite as a team and change the office dynamic to one that
        favors cooperation, better communication & productivity. All that, and
        it's FUN! Get in touch with us today to schedule a session for your
        staff NOW!
      </GlobalCardComponent>
    </div>
  );
}
