import React from "react";
import {
  Button,
  Typography,
  CardActionArea,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

export const GlobalCardComponent = ({
  alt,
  image,
  title,
  open,
  handleClickOpen,
  handleClose,
  children,
}) => {
  return (
    <div>
      <Card elevation={5} sx={{ maxWidth: 800 }}>
        <CardActionArea>
          <CardMedia
            component="img"
            height="400"
            image={image}
            alt={`Flaco's Dance Factory ${alt}`}
          />

          <CardContent>
            <Typography gutterBottom variant="h6">
              {title}
            </Typography>
            <hr style={{ width: 326 }} />
          </CardContent>
        </CardActionArea>
        <CardActions>
          <Button variant="contained" color='primary' size="large" onClick={handleClickOpen}>
            Learn More
          </Button>
        </CardActions>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            opacity: "0.90",
            border: "2px",
          },
        }}
        fullWidth
        scroll='paper'
        maxWidth="xl"
      >
        <DialogContent>
          <DialogContentText>
            <Typography variant="h3">
              <strong>{title}</strong>
            </Typography>
            <hr />
          </DialogContentText>
          {children}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color='primary' size="large" onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
