import React from "react";
import HeroSection from "./components/HeroSection";

function App() {
  return (
      <HeroSection />
  );
}

export default App;
