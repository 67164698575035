import React, { useState } from "react";
import { GlobalCardComponent } from "../global-resources/GlobalCardComponent";

export default function PrivateLessonsCard() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setOpen((prev) => !prev);
  };
  const titleString = `Private Lessons`;
  return (
    <div>
      <GlobalCardComponent
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        title={titleString}
        image="https://images.unsplash.com/photo-1523354177913-be035fcee55e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2148&q=80"
        alt={"Private Lessons"}
      >
        Group classes are not the only way to study dance. Private lessons are
        available, as well. If large groups don't appeal to you, and the idea of
        getting 100% of the attention from your dance teacher are things that
        sound more up your alley, or even if you're getting ready to attend a
        wedding soon and just want to be more at ease on the dance floor, we get
        it. While there is a significant difference in the cost of each, the
        progress one can see makes it more than worthwhile! Our patient,
        nurturing teachers will guide you, and show you the tricks and shortcuts
        to looking like a pro! Private lessons also include video support, an
        important tool for practice between lessons. Call us to schedule yours
        now!!
      </GlobalCardComponent>
    </div>
  );
}
