import React from 'react';
import { Grid, CardMedia, Container } from '@mui/material';

import part1 from '../components/partners1.jpg';
import part2 from '../components/partners2.png';
import part3 from '../components/partners3.png';
import part4 from '../components/partners4.png';
import part5 from '../components/partners5.jpg';
import part8 from '../components/partners8.jpeg';

import './Partners.css';
import BookingSection from './BookingSection';


const PartnersComponent=()=>{


    return (
        <div>
            <Container>
        <Grid 
            container
            spacing={3}
            direction='row'
            alignItems='center'
            justifyContent='center'>
                        <Grid item sm={6} md={4}>
                            <CardMedia
                                className='mycard'
                                component='img'
                                image={part1}
                                alt='some link'
                                onClick={()=>window.open('http://www.daddances.com')}/>

                        </Grid>
                        <Grid item sm={6} md={4}>
                            <CardMedia
                                className='mycard'
                                component='img'
                                image={part2}
                                alt='some link'
                                onClick={()=>window.open('https://paintedbride.org')}/>

                        </Grid>
                        <Grid item sm={6} md={4}>
                            <CardMedia
                                className='mycard'
                                component='img'
                                image={part3}
                                alt='some link'
                                onClick={()=>window.open('https://mtairylearningtree.org')}/>

                        </Grid>
                        <Grid item sm={12} md={4}>
                            <CardMedia
                                className='mycard'
                                component='img'
                                image={part4}
                                alt='some link'
                                onClick={()=>window.open('https://www.artsednj.org')}/>

                        </Grid>
                        
                        <Grid item sm={6} md={4}>
                            <CardMedia
                                className='mycard'
                                component='img'
                                image={part5}
                                alt='some link'
                                onClick={()=>window.open('https://www.facebook.com/siempresalsaphilly/')}/>

                        </Grid>
                        <Grid item sm={6} md={4}>
                            <CardMedia
                                className='mycard'
                                component='img'
                                src='https://www.xoedge.com/myaccount/2019/website-share/VendorBadge_AsSeenOnWeb.png'
                                alt='some link'
                                onClick={()=>window.open('https://www.theknot.com/marketplace/redirect-2069882?utm_source=vendor_website&utm_medium=banner&utm_term=3141467a-eabb-447a-be8c-28394766596b&utm_campaign=vendor_badge_assets')}/>

                        </Grid>
                        <Grid item sm={6} md={4}>
                            <CardMedia
                                className='mycard'
                                component='img'
                                image={part8}
                                alt='some link'
                                onClick={()=>window.open('https://www.yanjep.org/')}/>

                        </Grid>



        </Grid>
        </Container>
        <BookingSection/>
        </div>
    )
}

export default PartnersComponent