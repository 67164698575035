import React, { useEffect } from "react";
import { Grid, Container, Box } from "@mui/material";
import Aos from "aos";
import "aos/dist/aos.css";
import ChoreographicCard from "../../cards/ChoreographicCard";
import GroupClassesCard from "../../cards/GroupClassesCard";
import PrivateLessonsCard from "../../cards/PrivateLessonsCard";
import RentSpaceCard from "../../cards/RentSpaceCard";
import SchoolCard from "../../cards/SchoolCard";
import TeamBuilding from "../../cards/TeamBuilding";
import EntertainmentCard from "../../cards/EntertainmentCard";
import SocailCard from "../../cards/SocialCard";
import PartnersComponent from "../PartnersComponent";

export default function BodySection() {
  const transComponents = [
    <ChoreographicCard key={"1"} />,
    <GroupClassesCard key={"2"} />,
    <SchoolCard key={"3"} />,
    <TeamBuilding key={"4"} />,
    <EntertainmentCard key={"5"} />,
    <PrivateLessonsCard key={"6"} />,
    <RentSpaceCard key={"7"} />,
    <SocailCard key={"8"} />,
  ];

  useEffect(() => {
    Aos.init({});
  }, []);

  return (
   <Box>
        <Container maxWidth="xl">
          <div>
            <Grid
              container
              spacing={4}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              {transComponents.map((item, index) => (
                <Grid item sm={12} md={6} key={index}>
                  <div
                    data-aos="fade-up"
                    data-aos-offset="200"
                    data-aos-easing="ease-in-sine"
                    data-aos-duration="600"
                  >
                    {item}
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </Container>
        <PartnersComponent/>
   </Box>
  );
}
