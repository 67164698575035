import { Grid, Box } from "@mui/material"
import './flyer.css'

export const GlobalFlyerComponent = (props) => {
   
    return (
        <Box>
            <Grid
                container
                direction='row'
                justifyContent='center'
                alignItems='center'
            >
                <Grid item>
                    <img src={props.image} alt={'Flaco'} className={'center'}/>
                </Grid>
            </Grid>
        </Box>
    )
}