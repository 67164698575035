import React, { useState } from 'react';
import { GlobalCardComponent } from '../global-resources/GlobalCardComponent';



export default function ChoreographicCard() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(prev => !prev);
  };
  const handleClose = () => {
    setOpen((prev) => !prev);
  };


  const titleString = `*Choreography Services (Sweet 16's, Bands, Wedding Services, etc.)`;
  return (
    <div>
      <GlobalCardComponent
          handleClickOpen={handleClickOpen}
          handleClose={handleClose}
          open={open}
          title={titleString}
          image={"https://images.unsplash.com/photo-1606216794074-735e91aa2c92?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80"}
          alt={"Choreography Services"}
          >
        We can make that special day even more special with the perfect piece of choreography that you will be proud to watch and show off on video for years to come!
        Just bring us your song, and we'll make something especially for you! No song in mind??? We can fit you up with some steps from a style you choose that match your ability and timeline! Choosing a song will be easy after that.
        We also make steps for bands & supply dancers for film and stage projects.
        <br />
        <br />
        <br />
        <br />
        *Deposits are required for all specialized services such as private sessions, choreography services, etc. <strong>Deposits are non-refundable.</strong>
        Cancellation of a session must occur at least 72 hours prior to scheduled appointment. We allow for one cancellation; however, any additional cancellation may result in forfeiture (at the management discretion).
        <br />
        <br />

        <strong>
          Bulk rate only applies to contracts which are paid in full and in advance.
        </strong>

      </GlobalCardComponent>
    </div>
  );
}