import React from "react";
import { Grid } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import "./FooterStyles.css";
import footlogo from "./sflogo.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";

function FooterComponent() {
  return (
    <div style={{ marginTop: "50px" }} className="footer">
      <Grid
        container
        // spacing={3}
        // className='footer'
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item md={3} sm={12}>
          <img
            src={footlogo}
            height="100%"
            width="100%"
            alt="Flaco Dance Studio"
          />
        </Grid>

        <Grid item md={3} sm={12}>
          <LocationOnIcon
            style={{
              color: "white",
              pointerEvents: "all",
              position: "relative",
              right: "20px",
              top: "5vh",
            }}
            fontSize="large"
          />
          <h4 style={{ color: "white", position: "relative", left: "20px" }}>
            Flaco's Dance Factory, 411 York Rd <br />
            Jenkintown, PA 19046
          </h4>

          <LocalPhoneIcon
            style={{
              color: "white",
              pointerEvents: "all",
              position: "relative",
              right: "20px",
            }}
            fontSize="large"
          />
          <h4
            style={{
              color: "white",
              position: "relative",
              left: "20px",
              bottom: "5vh",
            }}
          >
            (267) 972-3672
          </h4>

          <MailIcon
            style={{
              color: "white",
              pointerEvents: "all",
              position: "relative",
              right: "20px",
              bottom: "4vh",
            }}
            fontSize="large"
          />
          <h4
            style={{
              color: "white",
              position: "relative",
              left: "20px",
              bottom: "8vh",
            }}
          >
            StudioFlaco411@gmail.com
          </h4>
        </Grid>
        <Grid item md={3} sm={12}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d381.50821254793004!2d-75.1262695870646!3d40.0962480829912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6b1565bc4aaa5%3A0x98f451287370c2b8!2sFlaco&#39;s%20Dance%20Factory!5e0!3m2!1sen!2sus!4v1644176916254!5m2!1sen!2sus"
            width="100%"
            height="100%"
            style={{ border: 0, padding: "20px" }}
            title="flaco map"
            loading="lazy"
          />
          <Grid container justifyContent="space-between" alignItems="center">
            <FacebookIcon
              style={{ color: "white", pointerEvents: "all" }}
              fontSize="large"
              onClick={() =>
                window.open(
                  "https://www.facebook.com/pages/category/Dancer/Flacos-Dance-Factory-147479238643798/"
                )
              }
            />
            <YouTubeIcon
              style={{ color: "white", pointerEvents: "all" }}
              fontSize="large"
              onClick={() =>
                window.open("https://www.youtube.com/@flacosdancefactory1")
              }
            />
            <InstagramIcon
              style={{ color: "white", pointerEvents: "all" }}
              fontSize="large"
              onClick={() =>
                window.open(
                  "https://www.instagram.com/flacosdancefactory/?hl=en"
                )
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}

export default FooterComponent;
