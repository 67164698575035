import React from "react";
import "./HeroSection.css";
import { GlobalFlyerComponent } from "../global-resources/GlobalFlyerComponent";
import FlacoDanceFlyer from "./flacoGiftFlyer.png";
import { Grid, Box } from "@mui/material";
import BodySection from "./body-section/BodySection";

function HeroSection() {
  return (
    <div>
      <Box style={{ backgroundColor: "black" }}>
        <Grid
          container
          direction={"column"}
          justifyItems={"center"}
          spacing={4}
        >
          <Grid item>
            <GlobalFlyerComponent image={FlacoDanceFlyer} />
          </Grid>
        </Grid>
      </Box>
      <br/>
      <BodySection />
    </div>
  );
}

export default HeroSection;
