import React, { useState } from "react";
import { Box, TextField, Grid, Button, Container, Dialog } from "@mui/material";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import emailjs from "@emailjs/browser";

export default function ContactForm() {
  const [contactName, setContactName] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactMessage, setContactMessage] = useState("");
  const [open, setOpen] = useState(false);

  const myClickHandle = () => {
    setOpen(!open);
  };

  const setNameHandler = (e) => {
    setContactName(e.target.value);
  };

  const setEmailHandler = (e) => {
    setContactEmail(e.target.value);
  };

  const setMessageHandler = (e) => {
    setContactMessage(e.target.value);
  };

  // working
  const onSubmitHandler = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_USER_ID
      )
      .then((result) => console.log(result.text), setOpen(!open))
      .catch((error) => console.log(error.text));

    setContactName("");
    setContactEmail("");
    setContactMessage("");
  };
  return (
    <Grid container direction="row">
      <Container maxWidth="xl">
        <Dialog open={open} onClose={myClickHandle}>
          <Stack sx={{ width: "100%" }} spacing={2}>
            <Alert onClose={myClickHandle} severity="success">
              {`Thank you! --We will reach out shortly`}
            </Alert>
          </Stack>
        </Dialog>
        <Box component="div">
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <form onSubmit={onSubmitHandler} style={{ width: "50vw" }}>
              <Grid item lg={12} md={12} sm={12}>
                <TextField
                  fullWidth
                  required
                  margin="normal"
                  label="name"
                  name="contactName"
                  value={contactName}
                  onChange={setNameHandler}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <TextField
                  // style={{minWidth: '40vw'}}
                  fullWidth
                  required
                  margin="normal"
                  label="email"
                  name="contactEmail"
                  value={contactEmail}
                  onChange={setEmailHandler}
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12}>
                <TextField
                  // style={{minWidth: '40vw'}}
                  fullWidth
                  required
                  multiline
                  margin="normal"
                  rows={6}
                  label="message"
                  name="contactMessage"
                  value={contactMessage}
                  onChange={setMessageHandler}
                />
              </Grid>
              <Button fullWidth variant="contained" size="large" type="submit">
                Submit
              </Button>
            </form>
          </Grid>
        </Box>
      </Container>
    </Grid>
  );
}
