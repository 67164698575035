import React from "react";
import { Grid, Container, Box } from "@mui/material";
import ContactForm from "./ContactForm";
import "../css/ContactSection.css";
import FooterComponent from "./FooterComponent";

export default function ContactSection() {
  return (
    <Box component="div" className="contact-bg">
      <Container maxWidth="xl">
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item lg={6} xs={12} sm={6}>
            <h1 className="contact-text-h1">{`We want to hear from you!`}</h1>
          </Grid>
          <Grid item lg={6} xs={12} sm={6}>
            <ContactForm />
          </Grid>
        </Grid>
      </Container>
      <FooterComponent />
    </Box>
  );
}
