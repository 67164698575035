import React, { useState } from "react";
import FlacoRent from "../components/flacoRent.jpg";
import { GlobalCardComponent } from "../global-resources/GlobalCardComponent";

export default function RentSpaceCard() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setOpen((prev) => !prev);
  };
  const titleString = `Rent Our Space`;
  return (
    <div>
      <GlobalCardComponent
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        title={titleString}
        image={FlacoRent}
        alt={"Rent our Space"}
      >
        In our efforts to support performing arts, arts education and creation,
        we are proud to offer our space to other artists and arts organizations
        at very affordable hourly rates. The space has 12-foot ceilings, and a
        1,600 sq. ft. & a wide open floor plan! Of course, rates will vary based
        on day, time and number of hours needed, but our open floor plan,
        roll-in first floor access, mirrored space features hardwood floors &
        free Wifi, and it's the perfect space for creative folks, but it's also
        a very versatile space. Add tables and chairs, and you have a great
        meeting space! Balloons on those tables make a great birthday! Other
        services are also available to add on, so ask us. We'll tell you what
        else is available.
      </GlobalCardComponent>
    </div>
  );
}
