import React, { useState } from "react";
import { Grid } from "@mui/material";
import { GlobalCardComponent } from "../global-resources/GlobalCardComponent";
import { GlobalRegisterLink } from "../global-resources/GlobalRegisterLink";

export default function GroupClassesCard() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setOpen((prev) => !prev);
  };
  const titleString = `Studio Group Classes & Schedule`;
  return (
    <div>
      <GlobalCardComponent
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        title={titleString}
        image="https://images.unsplash.com/photo-1484712401471-05c7215830eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80"
        alt={"Group Classes & Schedule"}
      >
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          alignItems={"stretch"}
        >
          <Grid item>
            <iframe
              src={process.env.REACT_APP_DANCE_API}
              width="100%"
              height="500vh"
              scrolling="yes"
              frameBorder="0"
              title="Studio Flaco Availability"
            />
          </Grid>
          <Grid item>
            <GlobalRegisterLink />
          </Grid>
        </Grid>
      </GlobalCardComponent>
    </div>
  );
}
