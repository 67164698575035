import React, { useState } from "react";
import { Typography, Grid } from "@mui/material";
import { GlobalCardComponent } from "../global-resources/GlobalCardComponent";

export default function SocailCard() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  const titleString = `Social, Nightlife & Other Fun`;

  return (
    <div>
      <GlobalCardComponent
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        title={titleString}
        image="https://images.unsplash.com/photo-1618165831366-97b8e3cb4060?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80"
        alt={"Social, Nightlife"}
      >
        <Grid container direction={"row"} justifyContent={"center"}>
          <Grid item>
            <Typography variant="h3">Under Construction!</Typography>
          </Grid>
        </Grid>
      </GlobalCardComponent>
    </div>
  );
}
