import { createTheme } from '@mui/material/styles'

const primaryColor = '#F6530D';
// const secondaryColor = '#F0C026';
// const tertiaryColor = '#4D9CAF';

const primaryBackgroundColor = '#F8F9FA';

export const markTheme = createTheme({
    palette: {
        background: {
            default: primaryBackgroundColor,
    
        },
        primary: {
            main: primaryColor,
            contrastText: '#FFFFFF'
        }
    },
components: {

}
});
