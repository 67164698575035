import { Box, Grid } from "@mui/material";
import React from "react";
import ContactSection from "./ContactSection";

export default function BookingSection() {
  return (
    <Box component={'div'}>
      <Grid container direction={"row"}>
        <Grid item xs={12} md={12} lg={12}>
          <iframe
            src={process.env.REACT_APP_DANCE_API}
            style={{minHeight: '50vh', width: '100%'}}
            title="Studio Flaco Schedule"
            scrolling="yes"
            frameBorder="0"
          />
        </Grid>
      </Grid>
      <ContactSection/>
    </Box>
  );
}
