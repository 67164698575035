import React, { useState } from "react";
import { GlobalCardComponent } from "../global-resources/GlobalCardComponent";

export default function EntertainmentCard() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen((prev) => !prev);
  };
  const handleClose = () => {
    setOpen((prev) => !prev);
  };

  const titleString = `Entertianment`;
  return (
    <div>
      <GlobalCardComponent
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        open={open}
        title={titleString}
        image="https://images.unsplash.com/photo-1618165831366-97b8e3cb4060?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1364&q=80"
        alt={"Entertainment Services"}
      >
        Although we offer a wide array of services here at The Factory, we are
        always available to bring our services to you! Whether you need an
        interactive dance experience to engage your guests at your event, a
        dance performance to entertain them, or something to celebrate a
        particular culture from around the world, we've got you covered. Do you
        need a DJ? Band? Photo booth? We have all your entertainment needs in
        one place.
      </GlobalCardComponent>
    </div>
  );
}
